/* eslint-disable no-console */
import Vue from "vue";
import App from "./App";
import { router } from "./router";
import Vant from "vant";

import "vant/lib/index.css";
import "./App.less";
//在main.js中引入
import '@vant/touch-emulator';

Vue.use(Vant);

import formCreate from "@form-create/element-ui";
import ElementUI from 'element-ui';

Vue.use(formCreate);
Vue.use(ElementUI);

import 'element-ui/lib/theme-chalk/index.css';

import "@/assets/css/highLight.css";

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
