<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view
            :key="$route.fullPath"
            v-if="!$route.meta.keepAlive"
        />
    </div>
</template>

<script>
    export default {
        created() {},
        data() {
            return {};
        },
    };
</script>

<style>

</style>
