/* eslint-disable no-console */
import axios from "axios";
import {
	Toast
} from "vant";

const TIMEOUT = 1000 * 12;

export const baseURL = {
	// test: "https://v1.2.tunnelprj.com/oa/admin-api",
	test: "http://192.168.2.200:48080/admin-api",
	pro: "https://v1.2.tunnelprj.com/oa/admin-api",
} [process.env.VUE_APP_ENV];

const server = axios.create({
	baseURL,
	withCredentials: true, // 默认开启凭证：请求中携带cookie
	timeout: TIMEOUT, // 超时设置：12秒
	headers: {
		"Content-Type": "application/json", // 默认请求格式JSON
		// "Cache-Control": "no-cache", // 默认请求不缓存
	},
});

const whiteArr = ["system/auth/login-others"];

// 请求拦截器: 每次请求前，如果存在token则在请求头中携带token
server.interceptors.request.use((config) => {
	// [扩展] 可扩展自定义请求头，eg：["Authorization"]等
	if (localStorage.getItem("accessToken") && !whiteArr.includes(config.url)) {
		config.headers["Authorization"] = localStorage.getItem("accessToken");
	}
	return config;
});

// 响应拦截器
server.interceptors.response.use(
	(response) => {
		if (response.data.status == 500) {
			Toast.fail(response.data.message);
		}
		return Promise.resolve(response.data);
	},
	(errorResponse) => {
		return Promise.resolve(errorResponse);
	}
);

export const get = (url = "", params, config) => {
	return server.get(url, {
		params,
		...(config || {})
	});
};

export const post = (url = "", data, config) => {
	return server.post(url, data, config);
};

export const put = (url = "", data, config) => {
	return server.put(url, data, config);
};

export const del = (url = "", params = {}) => {
	return server.delete(url, {
		params
	});
};
export const delPost = (url = "", data) => {
	return server.delete(url, {
		data
	});
};