/* eslint-disable no-unused-vars */
import { get, post, put } from "@/utils/HttpUtil";

export const getUserInfo = (userId) =>
  get("/system/auth/login-others", {
    id: userId,
  });

export const getUserLog = (params) => get("/system/work-log/page", params);

export const updateUserLog = (params) => put("/system/work-log/update", params);

export const getEmpsList = (params) => get("/system/work-log/page", params);
