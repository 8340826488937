import Vue from "vue";
import VueRouter from "vue-router";
import { getURLParameters } from "./utils/DomUtil";
import { getUserInfo } from "./api/user";

Vue.use(VueRouter);

const routes = [
  {
    name: "demo",
    component: (resolve) => {
      require(["./view/demo"], resolve);
    },
    meta: {
      title: "集合",
    },
  },
  {
    name: "send",
    component: (resolve) => {
      require(["./view/send"], resolve);
    },
    meta: {
      title: "发起流程",
    },
  },
  {
    name: "myRequest",
    component: (resolve) => {
      require(["./view/myRequest"], resolve);
    },
    meta: {
      title: "草稿箱",
    },
  },
  {
    name: "sendInfo",
    component: (resolve) => {
      require(["./view/sendInfo"], resolve);
    },
    meta: {
      title: "发起流程详情",
    },
  },
  {
    name: "todo",
    component: (resolve) => {
      require(["./view/todo"], resolve);
    },
    meta: {
      title: "待办列表",
    },
  },
  {
    name: "todoInfo",
    component: (resolve) => {
      require(["./view/todoInfo"], resolve);
    },
    meta: {
      title: "待办详情",
    },
  },
  {
    name: "history",
    component: (resolve) => {
      require(["./view/history"], resolve);
    },
    meta: {
      title: "我的流程",
    },
  },
  {
    name: "historyInfo",
    component: (resolve) => {
      require(["./view/historyInfo"], resolve);
    },
    meta: {
      title: "我的流程详情",
    },
  },
  {
    name: "doDone",
    component: (resolve) => {
      require(["./view/doDone"], resolve);
    },
    meta: {
      title: "我的已办",
      keepAlive: true,
    },
  },
  {
    name: "flowMonitoring",
    component: (resolve) => {
      require(["./view/flowMonitoring"], resolve);
    },
    meta: {
      title: "流程监控",
      // keepAlive: true,
    },
  },
  {
    name: "msgNotify",
    component: (resolve) => {
      require(["./view/msgNotify"], resolve);
    },
    meta: {
      title: "消息通知",
      // keepAlive: true,
    },
  },
  {
    name: "log",
    component: (resolve) => {
      require(["./view/log"], resolve);
    },
    meta: {
      title: "工作微博",
    },
  },
  {
    name: "empLog",
    component: (resolve) => {
      require(["./view/empLog"], resolve);
    },
    meta: {
      title: "员工微博",
    },
  },
  {
    name: "error",
    component: (resolve) => {
      require(["./view/error"], resolve);
    },
    meta: {
      title: "错误",
    },
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new VueRouter({ routes });

const init = async (userId) => {
  return new Promise((resolve, reject) => {
    getUserInfo(userId)
      .then((res) => {
        const accessToken = "Bearer " + res.data.accessToken;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("deptId", res.data.deptId);
        localStorage.setItem("postId", res.data.postId);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("userId", userId);
        localStorage.setItem("bankAccount", res.data.bankAccount);
        localStorage.setItem("bankAccountNumber", res.data.bankAccountNumber);

        resolve();
      })
      .catch((err) => {
        console.log("获取 user 相关信息 err -> :", err);
        reject();
      });
  });
};

const authUserInfo = async (to, from, next) => {
  const { userID: userIdParams, userId: userIdParamsId } = getURLParameters();
  const userIdLocal = localStorage.getItem("userId");
  const userId = userIdParams || userIdParamsId || userIdLocal;

  if (to.path === "/error") {
    await next();
  } else if (userId) {
    await init(userId);
    await next();
  } else {
    await next({ path: "/error" });
  }

  // if (to.path === "/error") {
  //     await next();
  // } else if (!userIdParams && !userIdLocal) {
  //     await next({ path: "/error" });
  // } else {
  //     if (userIdLocal) {
  //         if (userIdParams && userIdParams !== userIdLocal) {
  //             await init(userIdParams)
  //                 // .then(() => {
  //                 //   next();
  //                 // })
  //                 .catch(() => {
  //                     next({ path: "/error" });
  //                 });
  //             await next();
  //         } else {
  //             await next();
  //         }
  //     } else {
  //         await init(userIdParams);
  //         await next();
  //     }
  // }
};

router.beforeEach((to, from, next) => {
  try {
    const title = to.meta && to.meta.title;
    if (title) {
      document.title = title;
    }
    authUserInfo(to, from, next);
  } catch (error) {}
});

export { router };
